import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  set_global_alertMsg,
  set_global_alertOpen,
  set_global_severity
} from '@/reduxtoolkit/globalSlice';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export default function MuiAlertCustom() {
  const dispatch = useDispatch();
  const { severity, alertMsg, alertOpen } = useSelector((s) => s.globalSlice);

  const handleClose = (event, reason) => {
    dispatch(set_global_alertOpen(false));
    dispatch(set_global_alertMsg(''));
    dispatch(set_global_severity(''));
  };

  return (
    <Snackbar
      open={alertOpen || false}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {alertMsg}
      </Alert>
    </Snackbar>
  );
}
